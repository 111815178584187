.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
}

.header {
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid var(--color-border);
  z-index: 0;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: var(--control-min-size-touch);
}

.body {
  flex: 1;
  position: relative;
}
